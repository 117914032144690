const offsetFromTopElement = 60

const scrollToInternal = data => {
  try {
    window.scrollTo(data)
  } catch (e) {
    console.info('[ScrollTo] data:', data)
  }
}

export const scrollPositionY = () => {
  return (
    window.pageYOffset ||
    window.scrollY ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0
  )
}

export const scrollTop = () => {
  scrollToInternal({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}

export const scrollTo = async ref => {
  if (ref && ref.$el && ref.$el.scrollIntoView) {
    // scrollIntoView works with modals, but is not supported by all browser
    ref.$el.scrollIntoView({ behavior: 'smooth' })
  } else {
    const elementPosition = ref.$el.getBoundingClientRect().top

    scrollToInternal({
      top: elementPosition + scrollPositionY() - offsetFromTopElement,
      left: 0,
      behavior: 'smooth'
    })
  }
}

export const scrollToWithOffset = async (ref, offset) => {
  const elementPosition = ref.$el.getBoundingClientRect().top

  scrollToInternal({
    top: elementPosition + scrollPositionY() - offset,
    left: 0,
    behavior: 'smooth'
  })
}

export const scrollToError = async refs => {
  const sortedErrorPositions = (await extractErrorPositions(refs)).sort(
    (a, b) => a[1] - b[1]
  )

  if (sortedErrorPositions && sortedErrorPositions.length > 0) {
    //sortedErrorPositions[0][0].focus()
    if (sortedErrorPositions[0][0].focus) {
      sortedErrorPositions[0][0].focus()
    } else if (sortedErrorPositions[0][0].validate) {
      // fallback if element has nothing to focus, but child elements that can be validated
      sortedErrorPositions[0][0].validate()
    } else if (
      sortedErrorPositions[0][0].$el &&
      sortedErrorPositions[0][0].$el.scrollIntoView
    ) {
      // scrollIntoView works with modals, but is not supported by all browser
      sortedErrorPositions[0][0].$el.scrollIntoView({ behavior: 'smooth' })
    } else {
      scrollToInternal({
        top:
          sortedErrorPositions[0][1] + scrollPositionY() - offsetFromTopElement,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}

export const focusFirstElement = async (refs, showError = false) => {
  const validationErrors = []

  await Promise.all(
    refs.map(async element => {
      if (!(await element.validate(showError))) {
        validationErrors.push(element)
      }
    })
  )

  if (validationErrors.length > 0 && validationErrors[0].focus) {
    validationErrors[0].focus()
  }

  return validationErrors.length === 0
}

const extractErrorPositions = async refs => {
  const errorRefs = []

  await Promise.all(
    refs.map(async element => {
      if (!(await element.validate(false))) {
        errorRefs.push(element)
      }
    })
  )

  if (errorRefs && errorRefs.length > 0) {
    return errorRefs.map(x => [x, x.$el.getBoundingClientRect().top])
  } else {
    return []
  }
}
